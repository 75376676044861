import React, { useRef, useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import { customerValidate } from 'Pages/CustomerDetails/utils';
import TollInformation from 'components/TollInformation/TollInformation';
import { errorHelper } from 'utils/helpers/errorHelper';
import { queryClient } from 'react-query';
import { Customer } from '../types';
import CustomerForm from '../CustomerForm/CustomerForm';

interface CustomerDataProps {
  customerId: string;
}

async function fetchCustomerById(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.customerDetails,
    {
      customerId: id
    }
  );
  return data;
}

const STALE_TIME = 300000;

const CustomerData: React.FC<CustomerDataProps> = ({ customerId }) => {
  const [selectedTab, setSelectedTab] = useState<string>('customerDetails');
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const navigate = useNavigate();

  const {
    isError,
    data: customerData,
    isLoading
  } = useQuery({
    queryKey: ['customers', customerId],
    queryFn: () => fetchCustomerById(customerId),
    staleTime: STALE_TIME,
    enabled: !!customerId && selectedTab === 'customerDetails',
    refetchOnWindowFocus: false
  });

  const handleSave = async (
    values: Customer,
    formikHelpers: FormikHelpers<Customer>
  ) => {
    const isValidVatId = await handleVatIDValidation(String(values.vatID));
    if (!isValidVatId) {
      return;
    }
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.saveCustomer, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['customers', customerId]
        });
        successDialog('', 'Customer updated successfully');
      })
      .catch((error) => {
        errorDialog('', errorHelper(error, 'Failed to save  customer'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleVatIDValidation = async (vatID: string) => {
    if (vatID) {
      const isValidVatID = await verifyVatID(vatID);
      if (!isValidVatID) {
        const isConfirmed = await confirmCloseDialog(
          'Are you sure?',
          `VAT-ID ${vatID} is not valid. Do you want process anyway?`
        );
        if (!isConfirmed) {
          return false;
        }
      }
    }
    return true;
  };

  const verifyVatID = async (VatId: unknown) => {
    try {
      const { data } = await customerInstance.post(
        customerEndPoints.vatIdCheck,
        { VatId, customerId }
      );
      return data?.data;
    } catch (error) {
      return false;
    }
  };

  const handleSaveAs = async (values: Customer) => {
    const isValidVatId = await handleVatIDValidation(String(values.vatID));
    if (!isValidVatId) {
      return;
    }
    const { customerID, ...rest } = values;
    customerInstance
      .post(customerEndPoints.saveCustomer, rest)
      .then((response) => {
        const { data: newCustomerID } = response.data;
        successDialog('', 'New customer saved successfully');
        navigate(`/customer/${newCustomerID}?type=customer`);
      })
      .catch((error) => {
        errorDialog('', errorHelper(error, 'Failed to save as new customer'));
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Customer',
      'Are you sure you want to delete this customer?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteCustomer, { customerId })
      .then(() => {
        queryClient.removeQueries(['customers', customerId]);
        successDialog('', 'Customer deleted successfully');
        navigate('/');
      })
      .catch(() => {
        errorDialog('', 'Failed to delete customer');
      });
  };

  const handleValidateAndSave = () => {
    formRef.current?.validateForm().then((errors) => {
      if (Object.keys(errors).length) {
        Object.keys(errors).forEach((key) => {
          formRef.current?.setFieldTouched(key, true);
        });
      } else {
        handleSaveAs(formRef.current?.values as Customer);
      }
    });
  };

  const items = [
    {
      label: 'CUSTOMER DETAILS',
      key: 'customerDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={customerData}
              enableReinitialize
              validate={customerValidate}
              onSubmit={handleSave}
              innerRef={formRef}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className="p-2">
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                      data-testid="save-customer"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      disabled={isSubmitting}
                      data-testid="save-as-customer"
                      onClick={handleValidateAndSave}
                    >
                      Save As
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-customer"
                    >
                      Delete
                    </Button>
                  </div>
                  <CustomerForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation type="customer" id={customerId} colcoCountryTypeId={customerData?.colcoCountryTypeID} />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div className="bg-shellExtraPaleGrey2 w-full h-full">
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default CustomerData;
