import React from 'react';
import { Text } from '@sede-x/shell-ds-react-framework';
import { FormikValues, useFormikContext } from 'formik';

const Summary: React.FC = () => {
    const { values } = useFormikContext<FormikValues>();

    return (
        <div className="bg-shellExtraPaleGrey2 py-4" data-testid="obu-terminate-summary">
            <Text bold className='pl-20 pt-2'>Selected OBU:</Text>
            <Text className='pt-2 pl-20'>Serial number: {values.serialNo}</Text>
            <Text className='py-2 pl-20'>OBU product: {values.productName}</Text>
            <Text bold className='pl-20 pt-2'>Termination Reason: {values?.selectedReason}</Text>
        </div>
    );
}
export default Summary;
