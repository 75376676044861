import { Modal } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledModals = styled(Modal)`
  .shell-modal-container-content {
    height: auto;
    padding: 20px;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
`;
