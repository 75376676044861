import QueryError from 'components/QueryError';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import { Field, useFormikContext } from 'formik';
import NumberField from 'components/FormCmponents/NumberField';
import Table from 'components/Table/Table';
import { useSdsPagination } from 'hooks/use-pagination';
import {
  getOverviewColumns,
} from 'components/Table/columns/overviewColumns';
import { useState, useEffect } from 'react';
import { customerEndPoints } from 'api/apiEndpoints';
import { ColumnDef } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { SaveOBUOrderDto, ProductDetailDto } from './types';

const DEFAULT_PAGE_SIZE = 20;
interface OBUProductProps {
  customerId?: string;
  searchType: string;
}
async function fetchOBUProduct(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post(customerEndPoints.getOBUProducts, {
    customerId,
    pageNumber,
    pageSize
  });
}

const OBUProduct: React.FC<OBUProductProps> = ({ customerId, searchType }) => {

  const { setFieldValue } = useFormikContext<SaveOBUOrderDto>();
  const [selectedRows, setSelectedRows] = useState<ProductDetailDto[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['get-Obu-products', customerId, pageSize, pageNumber],
    queryFn: () =>
      fetchOBUProduct(customerId as string, pageNumber, pageSize).then(
        (res) => res.data
      ),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always'
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  useEffect(() => {
    if (selectedRows.length) {
      const row = selectedRows[0];
      setFieldValue('productTypeID', row.productTypeID);
      setFieldValue('obuProduct', row.name);
      setFieldValue('customerId', customerId);
    }
  }, [selectedRows]);

  return (
    <div
      className="flex flex-col grow overflow-y-auto px-4 py-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <QueryError isLoading={isLoading} isError={isError}>
      {searchType === 'orderObu' && <div className='w-1/3 pl-1'>
        <Field
          id="amount"
          name="amount"
          placeholder="Type here"
          label="OBU Number"
          mandatory
          component={NumberField} />
      </div>}
        <Table<ProductDetailDto>
          data={data?.data ?? []}
          columns={getOverviewColumns('OBUProduct') as ColumnDef<ProductDetailDto>[]}
          columnSelection={false}
          exportEnabled={false}
          enableMultiRowSelection={false}
          onSelectedRowsChange={setSelectedRows}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }} />
      </QueryError>
    </div>
  );
};

export default OBUProduct;
