import {
  Accordion,
  Avatar,
  Modal,
  Select
} from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledAvatar = styled(Avatar)`
  div {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  .shell-accordion-header {
    border: 1px solid #f0f0f0;
  }
  .shell-accordion-content-box {
    padding-top: 20px;
  }
`;

export const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: auto;
    padding: 20px;
  }
`;

export const StyledSelect = styled(Select)`
  &.shell-select-disabled {
    .shell-select-selector > .shell-select-selection-item {
      color: #999;
    }
  }
`;
