import { ErrorMessagesText } from './ErrorMessagesText';

interface VehicleModel {
  vehicleId: string;
  registrationNumber: string;
  country: string;
  category: string;
  emissionClass: string;
  axles: number;
  truckTractorAxlesNumber: number;
  trailerAxlesNumber: number;
  grossVehicleWeightRating: number;
  authorizedGrossVehicleWeight: number;
  vin: string | null;
  totalVehicleWeight: number;
  particleReductionClass: string | null;
  vehicleModelTypeId: string | null;
  manufacturer: string;
  firstDayOfRegistration: string;
  trailerMaxLadenWeight: number | null;
  trailerTypeId: string | null;
  weightF1: number | null;
  isEnabled: number;
}

interface VehicleSTVService {
  cardNumber: string;
}

const MAX_VEHICLE_WEIGHT = 65535.0;
const MAX_VIN_LENGTH = 17;

export class VehicleValidator {
  public static ValidateSelection(
    models: VehicleModel[],
    vehicleSTVService?: VehicleSTVService,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ): string[] {
    for (const vehicleModel of models) {
      const result = this.ValidateVehicle(
        vehicleModel,
        vehicleSTVService,
        pendingVehicles,
        vehiclesWithObu
      );
      if (result.length) {
        return result;
      }
    }

    return [];
  }

  public static ValidateForFormattingWithPendingVehicle(
    vehicleModel: VehicleModel,
    pendingVehicles: string[]
  ): string {
    let message = '';
    if (pendingVehicles.includes(vehicleModel.vehicleId)) {
      message = `${ErrorMessagesText.VehicleValidator_PendingVehicle}, RequestVehicle`;
    }

    return message;
  }

  public static ValidateForFormattingWithPendingVehicleAndObus(
    vehicleModel: VehicleModel,
    pendingVehicles: string[],
    vehiclesWithObus: string[]
  ): string[] {
    const messages = [];
    const result = VehicleValidator.ValidateForFormattingWithPendingVehicle(
      vehicleModel,
      pendingVehicles
    );
    if (result) {
      messages.push(result);
    }
    if (vehiclesWithObus.includes(vehicleModel.vehicleId)) {
      messages.push(ErrorMessagesText.VehicleValidator_ObuAlreadyAssigned);
    }

    return messages;
  }

  public static ValidateVehicle(
    vehicleModel: VehicleModel,
    vehicleSTVService?: VehicleSTVService,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ) {
    const message = [];
    if (!vehicleModel.registrationNumber) {
      message.push(
        ErrorMessagesText.VehicleValidator_RegistrationNumber(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (!vehicleModel.country) {
      message.push(
        ErrorMessagesText.VehicleValidator_Country(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (!vehicleModel.emissionClass) {
      message.push(
        ErrorMessagesText.VehicleValidator_EmissionClass(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (vehicleModel.axles <= 0) {
      message.push(
        ErrorMessagesText.VehicleValidator_Axles(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    if (
      !vehicleModel.grossVehicleWeightRating ||
      !(
        vehicleModel.grossVehicleWeightRating >= 1.0 &&
        vehicleModel.grossVehicleWeightRating <= MAX_VEHICLE_WEIGHT
      )
    ) {
      message.push(ErrorMessagesText.VehicleValidator_GrossWeight);
      return message;
    }

    if (
      !vehicleModel.authorizedGrossVehicleWeight ||
      !(
        vehicleModel.authorizedGrossVehicleWeight >= 1.0 &&
        vehicleModel.authorizedGrossVehicleWeight <= MAX_VEHICLE_WEIGHT
      )
    ) {
      message.push(ErrorMessagesText.VehicleValidator_GrossWeight);
      return message;
    }

    if (!vehicleModel.category) {
      message.push(
        ErrorMessagesText.VehicleValidator_Category(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    const weightMessage = this.CheckVehicleGrossWeight(vehicleModel);
    if (weightMessage) {
      message.push(weightMessage);
      return message;
    }

    // ------------ according to BUG  VSO 2089 -----------------//

    if (!vehicleModel.vin) {
      message.push(
        ErrorMessagesText.VehicleValidator_VIN(vehicleModel.registrationNumber)
      );
      return message;
    }

    // acc. to ISO norm a VIN number is 17 characters long

    if (vehicleModel.vin.length !== MAX_VIN_LENGTH) {
      message.push(
        ErrorMessagesText.VehicleValidator_VIN_Format(
          vehicleModel.registrationNumber
        )
      );
      return message;
    }

    message.push(...this.CheckVehicleSTVCardNumber(vehicleSTVService));

    message.push(
      ...this.ValidateVehicleWithPendingVehicleOBU(
        vehicleModel,
        pendingVehicles,
        vehiclesWithObu
      )
    );

    return message;
  }

  public static CheckVehicleSTVCardNumber(
    vehicleSTVService?: VehicleSTVService
  ): string[] {
    const message = [];
    if (vehicleSTVService) {
      const result = this.ValidateSTVCardNumber(vehicleSTVService);
      if (result) {
        message.push(ErrorMessagesText.VehicleValidator_STVCardNumberMissing);
      }
    }
    return message;
  }

  public static CheckVehicleGrossWeight(vehicleModel: VehicleModel): string {
    let message = '';
    if (
      !vehicleModel.authorizedGrossVehicleWeight ||
      !(
        vehicleModel.authorizedGrossVehicleWeight >= 1.0 &&
        vehicleModel.authorizedGrossVehicleWeight <= MAX_VEHICLE_WEIGHT
      )
    ) {
      message = ErrorMessagesText.VehicleValidator_GrossWeight;
    }

    return message;
  }

  public static ValidateVehicleWithPendingVehicleOBU(
    vehicleModel: VehicleModel,
    pendingVehicles?: string[],
    vehiclesWithObu?: string[]
  ): string[] {
    const message = [];
    if (pendingVehicles?.length && vehiclesWithObu?.length) {
      message.push(
        ...this.ValidateForFormattingWithPendingVehicleAndObus(
          vehicleModel,
          pendingVehicles,
          vehiclesWithObu
        )
      );
    } else if (pendingVehicles?.length) {
      message.push(
        this.ValidateForFormattingWithPendingVehicle(
          vehicleModel,
          pendingVehicles
        )
      );
    }

    return message;
  }

  public static ValidateSTVCardNumber(
    vehicleSTVService: VehicleSTVService
  ): string {
    const cardNumber = vehicleSTVService?.cardNumber;
    let message = '';

    if (!cardNumber) {
      message = ErrorMessagesText.VehicleValidator_STVCardNumberMissing;
    }

    return message;
  }

  public static ValidateForOpenedRequests(
    model: VehicleModel,
    openedVehicles: string[]
  ): string {
    let message = '';
    if (openedVehicles?.includes(model.vehicleId)) {
      message = ErrorMessagesText.VehicleValidator_PendingVehicle;
    }
    return message;
  }
}
