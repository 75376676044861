import {
  Alignments,
  Button,
  Flexbox,
  Heading,
  Icons,
  Modal,
  ModalAction,
  Sizes,
  TextTypes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { axiosInstance } from 'api';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import Table from 'components/Table/Table';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { ChangeEvent, useRef, useState } from 'react';
import { columnBuilder } from 'utils/helpers';
import { read, utils as xlsxUtils } from 'xlsx';
import { AxiosError } from 'axios';
import { ChooseFile, FileInput } from './style';

interface BEError {
  statusCode: string;
  success: string;
  messages: string[];
  apiVersion: string;
}

const czechTollColumns = columnBuilder([
  {
    field: 'accountCardNumber',
    label: 'Old Card Number'
  },
  {
    field: 'accountCardNumberNew',
    label: 'New Card Number'
  },
  {
    field: 'expiryDateNew',
    label: 'Card Expiry Date'
  },
  {
    field: 'vehicleLpn',
    label: 'Vehicle license plate number'
  }
]);

const slovakiaColumns = columnBuilder([
  {
    field: 'customerID',
    label: 'Customer ID'
  },
  {
    field: 'cardOldNumber',
    label: 'Old Card Number'
  },
  {
    field: 'cardNoNew',
    label: 'New Card Number'
  }
]);

const czechHeaders = [
  'accountCardNumber',
  'accountCardNumberNew',
  'expiryDateNew',
  'vehicleLpn'
];

const slovakiaHeaders = ['customerID', 'cardOldNumber', 'cardNoNew'];

const CardChangeUpload = ({
  onSuccess,
  menu
}: {
  onSuccess?: () => void;
  menu: string;
}) => {
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState<Array<unknown>>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleOnClose = () => {
    resetFileInput();
    setOpen(false);
  };

  const readUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = read(data, {
          type: 'binary',
          dateNF: 'yyyy/mm'
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsxUtils.sheet_to_json(worksheet, {
          raw: false,
          header:
            menu === 'slovakiaExpiredCards' ? slovakiaHeaders : czechHeaders
        });

        const [, ...result] = json;

        setTableData(result);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  const resetFileInput = () => {
    setTableData([]);
  };

  const actions = [
    {
      label: 'Clear',
      action: () => resetFileInput(),
      props: {
        variant: Variants.Outlined,
        iconPosition: 'left',
        size: 'xsmall',
        hidden: !tableData.length,
        'aria-hidden': !tableData.length,
        disabled: !tableData.length,
        'data-testid': 'clear-button'
      }
    },
    {
      label: 'Confirm',
      action: () => handleConfirm(),
      props: {
        size: 'xsmall',
        iconPosition: 'left',
        disabled: !tableData.length || isSubmitting,
        'aria-hidden': !tableData.length,
        hidden: !tableData.length,
        'data-testid': 'confirm-button'
      }
    }
  ];

  const handleConfirm = () => {
    setIsSubmitting(true);
    const api =
      menu === 'slovakiaExpiredCards'
        ? actionButtonEndpoints.slovakiaUploadTemplate
        : actionButtonEndpoints.insertCardChangeRequests;
    axiosInstance
      .post(api, tableData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        successDialog(
          '',
          tableData.length > 1
            ? 'Records uploaded successfully'
            : 'Record uploaded successfully'
        );
        handleOnClose();
        onSuccess?.();
      })
      .catch((error) => {
        let message = 'Failed to upload records, please try again later';
        const axiosError = error as AxiosError;

        const { messages } = axiosError.response?.data as BEError;
        if (messages && Array.isArray(messages) && messages.length) {
          const result = messages.map((msg: string) => `<li>${msg}</li>`);
          message = `<ul class="list-disc 
          list-outside p-2 m-2">${result.join('')}</ul>`;
        }

        errorDialog('', message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Button
        data-testid="uploadButton"
        id="blockbtn"
        icon={<Icons.UploadCloud />}
        size="small"
        onClick={() => setOpen(true)}
      >
        Upload .xls
      </Button>
      <Modal
        width={tableData.length ? '60%' : '40%'}
        title="Bulk Upload"
        open={open}
        size={Sizes.Small}
        actions={actions as ModalAction[]}
        maskClosable={false}
        mask
        onClose={handleOnClose}
        contentScrollable
        actionsAlignment={Alignments.Right}
        centered
        bodyPadding={false}
        showFooter={!!tableData.length}
      >
        {!tableData.length && (
          <Flexbox
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <button
              type="button"
              className="flex flex-col p-6 cursor-pointer relative  justify-center items-center gap-2 "
              tabIndex={0}
              data-dropzone
              data-testid="dropzone"
              onKeyDown={(e) => {
                if (
                  (e.key === 'Enter' || e.code === 'Space') &&
                  inputRef.current
                ) {
                  inputRef.current.click();
                }
              }}
            >
              <div className="">
                <Icons.UploadCloud width={28} height={28} />
              </div>
              <Heading bold type={TextTypes.H2} className="text-center">
                Click to select
              </Heading>
              <div>
                <ChooseFile aria-hidden="true" alignItems="center" gap="4px">
                  <Icons.Add />
                  Choose file
                </ChooseFile>
              </div>
              <FileInput
                type="file"
                id="file-input"
                data-testid="file-input"
                name="file-input"
                accept=".xls,.xlsx"
                onChange={readUploadFile}
                tabIndex={-1}
                ref={inputRef}
                aria-hidden="true"
              />
            </button>
          </Flexbox>
        )}
        {!!tableData.length && (
          <div>
            <Table
              columns={
                menu === 'slovakiaExpiredCards'
                  ? slovakiaColumns
                  : czechTollColumns
              }
              data={tableData}
              exportEnabled={false}
              columnSelection={false}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CardChangeUpload;
