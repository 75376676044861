import { ErrorMessagesText } from './ErrorMessagesText';

interface ObuModel {
  id: string;
  obuSerialNumber: string;
  obuProductType: string;
  vehicleCountry: string;
  vehicleRegistrationNumber: string;
}

export class ObuValidator {
  public static ValidateForFormatting(
    model: ObuModel,
    pendingObus: string[]
  ): string {
    let message = '';
    if (pendingObus.includes(model.obuSerialNumber)) {
      message = ErrorMessagesText.VehicleValidator_PendingVehicle;
    }

    return message;
  }

  public static ValidateSerialNumber(model: ObuModel): string {
    let message = '';
    if (!model.obuSerialNumber) {
      message = ErrorMessagesText.ObuValidator_ObuInPendingState;
    }

    return message;
  }

  public static ValidateForOpenedRequests(
    model: ObuModel,
    openedObus: string[]
  ): string {
    let message = '';
    if (openedObus?.includes(model.id)) {
      message = ErrorMessagesText.VehicleValidator_PendingVehicle;
    }

    return message;
  }
}
