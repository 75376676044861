import React from 'react';
import { Menu, Icons } from '@sede-x/shell-ds-react-framework';
import { MenuInfo } from 'rc-menu/lib/interface';
import styled from 'styled-components';
import { StyledAvatar } from './style';
import TelepassIcon from '../../assets/icons/telepass_icon.png';
import t4e_icon from '../../assets/icons/t4e_icon.png';

interface PopupMenuProps {
  onMenuClick: (menu: MenuInfo) => void;
}

const StyledMenu = styled(Menu)`
  .shell-menu-item {
    border-bottom: 1px solid rgba(28, 28, 28, 0.14);
    min-height: 56px;
    align-items: center;
  }
`;

const menuItems = [
  {
    label: 'Actions',
    key: 'actions',
    itemIcon: <Icons.Target />,
    children: [
      {
        label: 'OBU',
        key: 'obu',
        itemIcon: <Icons.Sensor />,
        children: [
          {
            label: 'Telepass',
            key: 'telepass',
            itemIcon: <StyledAvatar size="small" src={TelepassIcon} />,
            children: [
              {
                label: 'KMaster Registration - Deregistration',
                key: 'obuKmasterRegistrationDeregistration'
              },
              {
                label: 'OBU Toll service modification',
                key: 'obuTollServiceModification'
              },
              {
                label: 'OBU Replacement',
                key: 'obuReplacement'
              },
              {
                label: 'Update',
                key: 'update'
              },
              {
                label: 'OBU Registration',
                key: 'obuRegistration'
              }
            ]
          },
          {
            label: 'T4E',
            key: 't4e',
            itemIcon: <StyledAvatar size="small" src={t4e_icon} />,
            children: [
              {
                label: 'Order and personalize OBU',
                key: 'orderAndPersonalizeObu'
              },
              {
                label: 'Order OBU',
                key: 'orderObu'
              },
              {
                label: 'Transfer OBU',
                key: 'transferObu'
              },
              {
                label: 'Personalize OBU',
                key: 'personalizeObu'
              },
              {
                label: 'Depersonalize OBU',
                key: 'depersonalizeObu'
              },
              {
                label: 'Depersonalize OBU (eTM only)',
                key: 'depersonalizeObuEtmOnly'
              },
              {
                label: 'Complete Transfer OBU',
                key: 'completeTransferObu'
              },
              {
                label: 'Terminate OBU',
                key: 'terminateObu'
              }
            ]
          }
        ]
      }
    ]
  }
];

export const ActionMenu: React.FC<PopupMenuProps> = ({ onMenuClick }) => (
  <div>
    <StyledMenu
      items={menuItems}
      mode="vertical"
      onClick={onMenuClick}
      selectable={false}
      data-testid="action-menu"
    />
  </div>
);
