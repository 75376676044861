import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import {
  getOverviewColumns,
  detailAction
} from 'components/Table/columns/overviewColumns';
import { useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { Text } from '@sede-x/shell-ds-react-framework';
import { Row } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { Drawer } from 'components/Drawer/Drawer';
import { customerEndPoints } from 'api/apiEndpoints';
import ObuDetails from '../ObuDetails/ObuDetails';
import { OBU } from '../types';
import AddObu from '../AddObu/AddObu';

interface CustomerDataProps {
  customerId?: string;
  vehicleId?: string;
}
export interface SearchPayload {
  pageNumber: number;
  pageSize: number;
  customerId?: string;
  requestId?: string;
}
const DEFAULT_PAGE_SIZE = 20;
const SEARCH_OBU_BY_CUSTOMER_KEY = 'search-obu-by-customer';
const SEARCH_OBU_BY_VEHICLE_KEY = 'search-obu-by-vehicle';

const ObuList: React.FC<CustomerDataProps> = ({ customerId, vehicleId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedObu, setSelectedObu] = useState<OBU | null>(null);
  const cacheKey = customerId
    ? SEARCH_OBU_BY_CUSTOMER_KEY
    : SEARCH_OBU_BY_VEHICLE_KEY;
  const endpoint = customerId
    ? customerEndPoints.searchObuByCustomer
    : customerEndPoints.searchObuByVehicle;

  const { data, isLoading, isError } = useQuery(
    [cacheKey, customerId ?? vehicleId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        endpoint,
        {
          pageNumber,
          pageSize,
          customerId,
          vehicleId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: 'always'
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  const handleClickDetails = (row: Row<unknown>) => {
    setSelectedObu(row.original as OBU);
    setOpenDetails(true);
  };

  const handleOnClose = () => {
    setOpenDetails(false);
    setSelectedObu(null);
  };

  const detailIcons = detailAction(handleClickDetails);

  return (
    <div
      className="flex flex-col grow overflow-y-auto pb-3 gap-2 bg-shellExtraPaleGrey2"
      data-testid="obu-list"
    >
      <div className={` bg-white p-5 ${vehicleId ? 'hidden' : ''}`}>
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            OBU List
          </Text>
          <div className="flex justify-end gap-3">
            <AddObu customerId={customerId} />
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable
          data={data?.data ?? []}
          columns={[...detailIcons, ...getOverviewColumns('CustomerOBU')]}
          columnSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          exportEnabled={false}
          stickyColumns={['expandAction']}
          selectedRows={{
            [selectedObu?.obuId as string]: true
          }}
          getRowId={(row) => (row as OBU).obuId}
        />
      </QueryError>
      <Drawer
        open={openDetails}
        header="OBU details"
        mask={false}
        onClose={handleOnClose}
        width="70%"
        bodyPadding={false}
        sticky
      >
        <div className="flex grow h-full">
          <ObuDetails
            obuId={selectedObu?.obuId}
            onSuccess={handleOnClose}
            cacheKey={cacheKey}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ObuList;
